<template>
	<div
		class="full-height"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box"
			:search="search"
			:option="search_option"
			:codes="codes"
			:user="user"

			@reset="reset"
			@click="getSearch"
			@agency="setAgency"
		>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.nice"
				@change="getSearch(1)"
			>
				<option value="">나이스 인증</option>
				<option
					v-for="add in codes.nice_confirm"
					:key="'nice_confirm_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.confirm"
				@change="getSearch(1)"
			>
				<option value="">심사 상태</option>
				<option
					v-for="add in codes.shop_confirm"
					:key="'shop_confirm_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.state"
				@change="getSearch(1)"
			>
				<option value="">계정 상태</option>
				<option
					v-for="add in codes.shop_state"
					:key="'shop_state_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
		</Search>

		<div class="mt-10 pa-10 bg-white full-height flex-column overflow-y-auto">

			<div class="justify-space-between">
				<div>총 <span class="font-weight-bold">{{ search.total_count }}</span> 건</div>

				<div class="text-right">
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch(1)"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
					<col width="80px" />
					<col width="150px" />
					<col width="auto" />
					<col width="120px" />
					<col width="150px" />

					<col width="150px" />
					<col width="150px" />
					<col width="150px" />
					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>관리번호</th>
					<th>아이디</th>
					<th>가맹점</th>
					<th>수수료율</th>
					<th>나이스 인증</th>

					<th>심사</th>
					<th>상태</th>
					<th>등록일</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{  item.idx  }}</td>
					<td>{{  item.id  }}</td>
					<td class="text-left">{{  item.shopName }}</td>
					<td>{{  item.shopFee }}</td>
					<td :class="'color-' + item.nice_color">{{  item.nice_name }}</td>

					<td :class="'color-' + item.confirm_color">{{ item.confirm_name }}</td>
					<td :class="'color-' + item.state_color">{{ item.state_name }}</td>
					<td>{{ item.regDate }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center mt-10 top-line-identify"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@finish="is_excel = !is_excel"
		></Excel>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
export default {
	name: 'MerchantList',
	components: {Excel, Search, Pagination},
	props: ['user', 'codes', 'date', 'Axios', 'query']
	,data: function(){
		return {
			program: {
				name: '가맹점 목록'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.query.page ? this.query.page : 1
				,size: this.query.size ? this.query.size : 10
				,total_count: 0
				,search_type: this.query.search_type ? this.query.search_type : ''
				,keyword: this.query.keyword ? this.query.keyword : ''
				,state: this.query.state ? this.query.state : ''
				,nice: this.query.nice ? this.query.nice : ''
				,confirm: this.query.confirm ? this.query.confirm : ''
				,agencyIdx: this.query.agencyIdx ? this.query.agencyIdx : ''
				,branchIdx: this.query.branchIdx ? this.query.branchIdx : ''
				,distributorIdx: this.query.distributorIdx ? this.query.distributorIdx : ''
				,resellerIdx: this.query.resellerIdx ? this.query.resellerIdx : ''
			}
			,search_option: {
				search_type: [
					{ name: '가맹점', column: 'name'}
				]
				,agency: true
			}
			,add_agency_type: this.codes.agency_type
			,items: [
				{ no: 1, idx: 1}
				,{ no: 2, idx: 2}
				,{ no: 3, idx: 3}
				,{ no: 4, idx: 4}
				,{ no: 5, idx: 5}
			]
			,is_excel: false
			,excel_data: {
				name: '가맹점 목록'
				,header: [
					{ key: 0, name: '아이디', column: 'id'}
					,{ key: 0, name: '가맹점명', column: 'shopName'}
					,{ key: 0, name: '수수료율', column: 'shopFee'}
					,{ key: 0, name: '정산주기', column: 'settlementCycle'}
					,{ key: 0, name: '1회 한도', column: 'oneLimit'}
					,{ key: 0, name: '월 한도', column: 'monthlyLimit'}
					,{ key: 0, name: '연 한도', column: 'yearlyLimit'}
					,{ key: 0, name: '정산은행', column: 'calculateBankName'}
					,{ key: 0, name: '계좌번호', column: 'calculateAccount'}
					,{ key: 0, name: '예금주', column: 'calculateAccountHolder'}
					,{ key: 0, name: '배대사 은행', column: 'bankName'}
					,{ key: 0, name: '배대사 계좌번호', column: 'account'}
					,{ key: 0, name: '배대사 예금주', column: 'accountHolder'}
					,{ key: 0, name: '사업자 구분', column: 'businessTypeName'}
					,{ key: 0, name: '사업자 번호', column: 'businessNumber'}
					,{ key: 0, name: '대표자명', column: 'name'}
					,{ key: 0, name: '휴대폰', column: 'hp'}
					,{ key: 0, name: '상태', column: 'stateName'}
					,{ key: 0, name: '심사', column: 'confirmName'}
					,{ key: 0, name: '상위 영업점 - 지사', column: 'branchName'}
					,{ key: 0, name: '총판', column: 'distributorName'}
					,{ key: 0, name: '대리점', column: 'agencyName'}
					,{ key: 0, name: '리셀러', column: 'resellerName'}
				]
				,content: null
			}
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.nice_confirm.length; i++){
					if(item.nice == self.codes.nice_confirm[i].code){
						item.nice_name = self.codes.nice_confirm[i].name
						item.nice_color = self.codes.nice_confirm[i].color
						break
					}
				}

				for(let i = 0; i < self.codes.shop_state.length; i++){
					if(item.state == self.codes.shop_state[i].code){
						item.state_name = self.codes.shop_state[i].name
						item.state_color = self.codes.shop_state[i].color
						break
					}
				}

				for(let i = 0; i < self.codes.shop_confirm.length; i++){
					if(item.confirm == self.codes.shop_confirm[i].code){
						item.confirm_name = self.codes.shop_confirm[i].name
						item.confirm_color = self.codes.shop_confirm[i].color
						break
					}
				}

				if(!item.shopFee){
					item.shopFee = '-'
				}else{
					item.shopFee += '%'
				}

				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'shop/'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.data
					this.search.total_count = result.data.totalCount
                    console.log(this.items, 'shop item')
					localStorage.setItem('query', JSON.stringify(this.search))
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,toDetail: function(item){
			this.$emit('push', { name: 'MerchantDetail', params: { idx: item.idx }, not_query: true})
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			//this.$emit('push', { name: this.$route.name, params: this.$route.params, query: this.search })

			this.getData()
		}

		,toExcel: async function(){

			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'shop/excel'
					,data: this.search
				})

				if(result.success){
					this.excel_data.content = result.data.data
					this.is_excel = true
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}

		}
		,setAgency: function(agency){
			this.search.branchIdx = this.user.branchIdx ? this.user.branchIdx : agency.branchList
			this.search.distributorIdx = this.user.distributorIdx ? this.user.distributorIdx : agency.distributorList
			this.search.agencyIdx = this.user.agencyIdx ? this.user.agencyIdx : agency.agencyList
			this.search.resellerIdx = this.user.resellerIdx ? this.user.resellerIdx : agency.resellerList
			this.getSearch(this.search.page)
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,total_count: 0
				,search_type: ''
				,keyword: ''
				,state: ''
				,nice: ''
				,confirm: ''
				,agencyIdx: ''
				,branchIdx: ''
				,distributorIdx: ''
				,resellerIdx: ''
			}

			this.getData()
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>